import React, { useState, useEffect } from 'react';
import ContaminantsDataService from '../services/ContaminantsDataService';
import ContaminantBarGraph from '../components/ContaminantBarGraph';
import { sectorsList } from '../assets/sectorsList';

const ExceedanceGraphsPage: React.FC = () => {
    const [sector, setSector] = useState<string>(sectorsList[0][0]);
    const [data, setData] = useState<SectorData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [displaySector, setDisplaySector] = useState<string>(sectorsList[0][1]);

    const getDisplayString = (sectorKey: string) => {
        const sector = sectorsList.find(sector => sector[0] === sectorKey);
        return sector ? sector[1] : undefined;
    };

    useEffect(() => {
        const getData = async () => {
            try {
                let dataService = new ContaminantsDataService('/api');
                const result = await dataService.fetchSectorData(sector);
                setData(result);
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }

        };

        getData();

        let display = getDisplayString(sector) ?? 'Unknown Sector';
        setDisplaySector(display);

    }, [sector]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h2>Exceedances graphs for all sectors</h2>
            <p>Select Sector:</p>
            <select
                value={sector}
                onChange={(e) => setSector(e.target.value)}
            >
                {sectorsList.map((option) => (
                    <option key={option[1]} value={option[0]}>
                        {option[1]}
                    </option>
                ))}
            </select>
            <ContaminantBarGraph data={data} sector={displaySector}/>
        </div>
    );
};

export default ExceedanceGraphsPage;