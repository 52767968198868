// src/components/HomePage.tsx
import React, { useEffect, useState } from 'react';
import ValueTable from '../components/ValueTable';
import ContaminantLineGraph from '../components/ContaminantLineGraph';
import Histogram from '../components/Histogram';
import { useParams } from 'react-router-dom';
import ContaminantsDataService from '../services/ContaminantsDataService';


const ContaminantPage: React.FC = () => {
    const { sector, contaminant_type } = useParams<{ sector: string; contaminant_type: string }>();
    const [data, setData] = useState<ContaminantsData[]>([]);
    const [histogramData, setHistogramData] = useState<HistogramData>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const getData = async () => {
            if (!sector || !contaminant_type) {
                setError('Invalid URL parameters.');
                setLoading(false);
                return;
            }

            try {
                let dataService = new ContaminantsDataService('/api');
                const result = await dataService.fetchAllContaminantsData(sector, contaminant_type);
                const histogramResult = await dataService.fetchHistogramData(sector, contaminant_type);
                console.log ('Result: ', result)
                console.log ('Histogram Result: ', histogramResult)
                setData(result);
                setHistogramData(histogramResult);
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [sector, contaminant_type]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <ContaminantLineGraph data={data} sector={sector} contaminant_type={contaminant_type}/>
            <Histogram data={histogramData} sector={sector} contaminant_type={contaminant_type}/>
            <ValueTable data={data} sector={sector} contaminant_type={contaminant_type}/>
        </div>
    );
};

export default ContaminantPage;
