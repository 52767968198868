import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface ContaminantBarGraphProps {
    data: SectorData[];
    sector?: string;
}

const ContaminantBarGraph: React.FC<ContaminantBarGraphProps> = ({ data, sector }) => {
    return (
        <div data-testid="contaminant-bar-graph">
            <h3>Total amount of exceedances for {sector}</h3>
            <div className='bar-graph'>
                <ResponsiveContainer width="100%" height={500}>
                    <BarChart
                        width={500}
                        height={500}
                        data={data}
                        margin={{
                            top: 20,
                            right: 100,
                            left: 100,
                            bottom: 60,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey='contaminant'
                            label={{ value: 'Contaminant', position: 'insideBottom', offset: -10 }} // Adjusted X-axis label position
                        />
                        <YAxis
                            label={{ value: 'Number of Exceedances', angle: -90, position: 'insideLeft', dx: -20 }} // Adjusted Y-axis label position
                        />
                        <Tooltip />
                        <Legend verticalAlign="top" height={40} />
                        <Bar type='monotone' dataKey={'exceedances'}/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
export default ContaminantBarGraph;
