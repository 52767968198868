/* src/App.tsx */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import LimitTable from './components/LimitTable';
import Sectors from './components/Sectors';
import ExceedanceGraphsPage from './pages/ExceedanceGraphsPage';
import ContaminantPage from './pages/ContaminantPage';
import './App.css';
import TotalContaminantPage from './pages/TotalContaminantPage';
import SectorsPage from './components/SectorsPage';

const App: React.FC = () => {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/misc-links" element={<Sectors />} />
                    <Route path='/sectors/:sector' element={<SectorsPage />} />
                    <Route path="/exceedance-graphs" element={<ExceedanceGraphsPage />} />
                    <Route path="/sector/:sector/:contaminant_type" element={<ContaminantPage />} />
                    <Route path="/sector/exceedances/:sector/:contaminant" element={<LimitTable />} />
                    <Route path="/sector/contaminants/:sector" element={<TotalContaminantPage/>} />
                </Routes>
            </div>
        </Router>
    );
};

// Home component to display on the "/" path
const Home: React.FC = () => (
    <div>
        <h1>Welcome to Poseidon</h1>
        <p>
            Introducing Poseidon, the ultimate industrial wastewater and sewage compliance monitor. Only about 3% of the world's water
            is drinkable, so it is important to make sure we preserve it. Poseidon offers a comprehensive compliance dashboard, tracking
            trends in sewage and industrial wastewater contaminants. By integrating and visualizing diverse datasets, Poseidon enables
            users to monitor pressures on regional water systems, ensuring regulatory compliance and promoting a healthier, sustainable
            environment with actionable insights. Whether you are a government official, an owner of a wastewater facility, or just a
            concerned citizen, stay ahead with real-time insights and ensure a cleaner, sustainable future.
        </p>
        <p>
            <br></br>
            <b>Please click the link in the navigation bar to view data for a specific Sector.</b>
        </p>
    </div>
);

export default App;
