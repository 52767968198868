import React, { useState } from 'react';
import './ValueTable.css';

interface ValueTableProps {
    data: ContaminantsData[];
    sector?: string;
    contaminant_type?: string;
}

const ValueTable: React.FC<ValueTableProps> = ({ data, sector, contaminant_type }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const rowsPerPage = 15;

    // Calculate the data to be displayed on the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

    // Handle page change
    const handleNextPage = () => {
        if (currentPage < Math.ceil(data.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="value-table-page">
            <h2>{sector} - {contaminant_type}</h2>
            <table className="value-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Facility Name</th>
                        <th>Sample Date</th>
                        <th>Contaminant</th>
                        <th>Reported As</th>
                        <th>Average</th>
                        <th>Minimum</th>
                        <th>Maximum</th>
                        <th>Units</th>
                    </tr>
                </thead>
                <tbody>
                    {currentRows.map((sample) => (
                        <tr key={sample.id}>
                            <td>{sample.id}</td>
                            <td>{sample.facilityName || 'N/A'}</td>
                            <td>{sample.sampleDate || 'N/A'}</td>
                            <td>{sample.contaminant || 'N/A'}</td>
                            <td>{sample.reportedAs || 'N/A'}</td>
                            <td>{sample.average}</td>
                            <td>{sample.minimum}</td>
                            <td>{sample.maximum}</td>
                            <td>{sample.units}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {Math.ceil(data.length / rowsPerPage)}</span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage >= Math.ceil(data.length / rowsPerPage)}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default ValueTable;
