import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar: React.FC = () => {
    // State for managing dropdown visibility
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // Function to toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <nav className="navbar">
            <h2 className="navbar-logo">Poseidon</h2>
            <div className="navbar-links">
                <Link to="/" className="navbar-link">
                    Home
                </Link>
                <div className="navbar-dropdown">
                    <button
                        className="navbar-link navbar-dropdown-button"
                        onClick={toggleDropdown}
                    >
                        Sectors
                    </button>
                    {isDropdownOpen && (
                        <div className="navbar-dropdown-menu">
                            <Link to="/sectors/organic-chemicals-manufacturing" className="navbar-dropdown-item">
                                Organic Chemicals Overview
                            </Link>
                        </div>
                    )}
                </div>

                <Link to="/exceedance-graphs" className="navbar-link">
                    Exceedance Graphs
                </Link>
                <Link to="/misc-links" className="navbar-link">
                    Misc Links
                </Link>
            </div>
        </nav>
    );
};

export default Navbar;