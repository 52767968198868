import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContaminantsDataService from '../services/ContaminantsDataService';
import ContaminantBarGraph from '../components/ContaminantBarGraph';

const TotalContaminantPage: React.FC = () => {
    const { sector } = useParams<{ sector: string }>();
    const [data, setData] = useState<SectorData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const getData = async () => {
            if (!sector) {
                setError('Invalid URL parameters.');
                setLoading(false);
                return;
            }

            try {
                let dataService = new ContaminantsDataService('/api');
                const result = await dataService.fetchSectorData(sector);
                setData(result);
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [sector]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p data-testid="error-message">{error}</p>;



    return (
        <div>
            <ContaminantBarGraph data={data} sector={sector} data-testid="contaminant-bar-graph" />
        </div>
    )

}
export default TotalContaminantPage;
