import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import ContaminantsDataService from '../services/ContaminantsDataService';
import ContaminantBarGraph from './ContaminantBarGraph';
import { contaminantsList } from '../assets/contaminantsList';
import './SectorsPage.css'

const SectorsPage: React.FC = () => {
    const { sector } = useParams<{ sector: string }>();
    const [data, setData] = useState<SectorData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // List of contaminants specific to Organic Chemical Manufacturing
    const organicChemicalContaminants = [
        { routeName: 'nitrogen', displayName: 'Nitrogen' },
        { routeName: 'carbon', displayName: 'Carbon' },
        { routeName: 'phenol', displayName: 'Phenol' },
        { routeName: 'phosphorus', displayName: 'Phosphorus' },
        { routeName: 'hydrogen-cyanide', displayName: 'Hydrogen Cyanide' },
        { routeName: 'sulphide', displayName: 'Sulphide' },
    ];

    useEffect(() => {
        const getData = async () => {
            if (!sector) {
                setError('Invalid URL parameters.');
                setLoading(false);
                return;
            }

            try {
                let dataService = new ContaminantsDataService('/api');
                const result = await dataService.fetchSectorData(sector);
                setData(result);
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [sector]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <ContaminantBarGraph data={data} sector={sector}/>
            <div className='sectors-page-lists'>
                <div>
                    <h2>View Contaminant Values</h2>
                    {organicChemicalContaminants.map((contaminant) => (
                        <div key={contaminant.routeName}>
                            <Link to={`/sector/organic-chemical-manufacturing/${contaminant.routeName}`}>
                                {contaminant.displayName}
                            </Link>
                        </div>
                    ))}
                </div>
                <div>
                    <h2>View Exceedance Data</h2>
                    {contaminantsList.map((contaminant) => {
                        return (
                            <div key={contaminant[0]}>
                                <Link to={`/sector/exceedances/${sector}/${contaminant[0]}`}>
                                    {contaminant[1]}
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default SectorsPage;