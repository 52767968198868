// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #007bff;
    padding: 10px 20px;
    color: white;
}

.navbar-logo {
    margin: 0;
    font-size: 1.5em;
}

.navbar-links {
    display: flex;
    gap: 15px;
}

.navbar-link {
    color: white;
    text-decoration: none;
    font-size: 1em;
}

.navbar-link:hover {
    text-decoration: underline;
}

.navbar-dropdown {
    position: relative;
}

.navbar-dropdown-button {
    background: #007bff;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.navbar-dropdown-button:hover {
    text-decoration: underline;
}

/* Menu Items */
.navbar-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    padding: 0.5rem 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    z-index: 100;
}

.navbar-dropdown-item {
    padding: 0.5rem 1rem;
    color: white;
    text-decoration: none;
}

.navbar-dropdown-item:hover {
    background-color: #444;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA,eAAe;AACf;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,sBAAsB;IACtB,iBAAiB;IACjB,0CAA0C;IAC1C,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["/* src/components/Navbar.css */\n.navbar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #007bff;\n    padding: 10px 20px;\n    color: white;\n}\n\n.navbar-logo {\n    margin: 0;\n    font-size: 1.5em;\n}\n\n.navbar-links {\n    display: flex;\n    gap: 15px;\n}\n\n.navbar-link {\n    color: white;\n    text-decoration: none;\n    font-size: 1em;\n}\n\n.navbar-link:hover {\n    text-decoration: underline;\n}\n\n.navbar-dropdown {\n    position: relative;\n}\n\n.navbar-dropdown-button {\n    background: #007bff;\n    border: none;\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n}\n\n.navbar-dropdown-button:hover {\n    text-decoration: underline;\n}\n\n/* Menu Items */\n.navbar-dropdown-menu {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    background-color: #333;\n    padding: 0.5rem 0;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);\n    border-radius: 4px;\n    display: flex;\n    flex-direction: column;\n    z-index: 100;\n}\n\n.navbar-dropdown-item {\n    padding: 0.5rem 1rem;\n    color: white;\n    text-decoration: none;\n}\n\n.navbar-dropdown-item:hover {\n    background-color: #444;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
