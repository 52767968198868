import React, { useEffect, useState } from 'react';
import {
    ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    ResponsiveContainer, Bar
} from 'recharts';

interface ContaminantLineGraphProps {
    data: ContaminantsData[];
    sector?: string;
    contaminant_type?: string;
}

interface GraphObject {
    sampleDate: string;
    [key: string]: string | number | number[];
    average: number;
    minMax: number[];
}

const ContaminantLineGraph: React.FC<ContaminantLineGraphProps> = ({ data, sector, contaminant_type }) => {

    const [graphData, setGraphData] = useState<GraphObject[]>([]);
    const [uniqueFacilities, setFacilities] = useState<string[]>([]);
    const [units, setUnits] = useState<string>();

    useEffect(() => {
        const getGraphData = () => {
            const dates = new Set<string>();
            const facilities = new Set<string>();
            const units = new Set<string>();

            data.forEach(element => {
                dates.add(element.sampleDate);
                facilities.add(element.facilityName);
                units.add(element.units);
            });

            const uniqueDates = Array.from(dates);
            setFacilities(Array.from(facilities));
            setUnits(Array.from(units)[0]);

            return uniqueDates.map(date => {
                const result: GraphObject = {
                    sampleDate: date,
                    average: 0,
                    minMax: [0, 0]
                };

                data.filter(entry => entry.sampleDate === date)
                    .forEach(entry => {
                        result.average = entry.average;
                        result.minMax = [entry.minimum, entry.maximum];
                        result[entry.facilityName] = entry.average;
                    });

                return result;
            });
        };

        setGraphData(getGraphData());
    }, [data]);

    // helper function bc sonarqube doesn't like returning mutating methods
    const toSort = (a: GraphObject, b: GraphObject) => {
        if (a.sampleDate < b.sampleDate) {
            return -1;
        }
        if (a.sampleDate > b.sampleDate) {
            return 1;
        }
        return 0;
    };

    // copy the graphData before sorting
    const sortedGraphData = [...graphData].sort(toSort);

    return (
        <div>
            <h3>Average {contaminant_type} in {units} for {sector}</h3>
            <div className="line-chart">
                <ResponsiveContainer width="100%" height={500}>
                    <ComposedChart
                        width={500}
                        height={500}
                        data={sortedGraphData}
                        margin={{
                            top: 20,
                            right: 100,
                            left: 100,
                            bottom: 60,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="sampleDate"
                            label={{ value: 'Date', position: 'insideBottom', offset: -10 }}
                        />
                        <YAxis label={{ value: 'Contaminant Value', angle: -90, position: 'insideLeft', dx: -20 }} />
                        <Tooltip />
                        <Legend verticalAlign="top" height={40} />
                        <Bar dataKey="minMax" fill="#003f82" />
                        {uniqueFacilities.map((facility, index) => (
                            <Line key={`facility-${index}`} type="monotone" dataKey={`${facility}`} />
                        ))}
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default ContaminantLineGraph;
