// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ValueTable.css */
.value-table-page {
    padding: 20px;
}

.value-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.value-table th,
.value-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.value-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.value-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.value-table tr:hover {
    background-color: #f1f1f1;
}

.loading-message {
    font-size: 1.2em;
    color: #555;
    margin-top: 20px;
}


.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.pagination button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pagination span {
    font-size: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/components/ValueTable.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,cAAc;AAClB;;AAEA;;IAEI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB;;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* src/components/ValueTable.css */\n.value-table-page {\n    padding: 20px;\n}\n\n.value-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 20px 0;\n}\n\n.value-table th,\n.value-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n}\n\n.value-table th {\n    background-color: #f4f4f4;\n    font-weight: bold;\n}\n\n.value-table tr:nth-child(even) {\n    background-color: #f9f9f9;\n}\n\n.value-table tr:hover {\n    background-color: #f1f1f1;\n}\n\n.loading-message {\n    font-size: 1.2em;\n    color: #555;\n    margin-top: 20px;\n}\n\n\n.pagination {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 20px;\n    gap: 10px;\n}\n\n.pagination button {\n    padding: 5px 10px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 3px;\n    cursor: pointer;\n}\n\n.pagination button:disabled {\n    background-color: #cccccc;\n    cursor: not-allowed;\n}\n\n.pagination span {\n    font-size: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
