export const contaminantsList = [
    ['iron', 'Iron'],
    ['nickel', 'Nickel'],
    ['cobalt', 'Cobalt'],
    ['copper', 'Copper'],
    ['boron', 'Boron'],
    ['aluminum', 'Aluminum'],
    ['lead', 'Lead'],
    ['selenium', 'Selenium'],
    ['flouride', 'Flouride'],
    ['zinc', 'Zinc'],
    ['phenol', 'Phenol'],
    ['sulphide', 'Sulphide'],
    ['nitrate', 'Nitrate'],
    ['unionized-ammonia', 'Unionized Ammonia'],
    ['phenanthrene', 'Phenanthrene'],
    ['dioxane', 'Dioxane'],
    ['total-phosphorus', 'Total Phosphorus'],
    ['total-ammonia-nitrogen', 'Total Ammonia Nitrogen'],
    ['low-ph', 'Low PH'],
    ['high-ph', 'High PH'],
    ['total-residual-chlorine', 'Total Residual Chlorine'],
    ['total-suspended-solids', 'Total Suspended Solids'],
    ['oil-and-grease', 'Oil And Grease'],
    ['biochemical-oxygen-demand', 'Biochemical Oxygen Demand'],
    ['toluene', 'Toluene'],
    ['carbonaceous-biochemical-oxygen-demand', 'Carbonaceous Biochemical Oxygen Demand'],
    ['total-petroleum-hydrocarbons', 'Total Petroleum Hydrocarbons'],
    ['acute-lethality-to-trout', 'Acute Lethality To Trout'],
    ['trichloroethane', 'Trichloroethane'],
    ['volatile-suspended-solids', 'Volatile Suspended Solids'],
    ['acute-lethality-to-daphnia', 'Acute Lethality To Daphnia'],
    ['dissolved-organic-carbon', 'Dissolved Organic Carbon'],
    ['turbidity', 'Turbidity'],
    ['temperature', 'Temperature']
]