import React, { useEffect, useState } from 'react';
import {
    BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    ResponsiveContainer, Bar
} from 'recharts';

interface HistogramProps {
    data?: HistogramData;
    sector?: string;
    contaminant_type?: string;
}

const Histogram: React.FC<HistogramProps> = ({ data, sector, contaminant_type }) => {

    const [graphData, setGraphData] = useState<HistogramGraphData[]>([]);

    useEffect(() => {

        if(data){
            const transformedData = data.counts.map((count, index) => ({
                name: `${data.ranges[index].toFixed(4)} - ${data.ranges[index + 1].toFixed(4)}`,
                count: count,
                binstart: data.ranges[index],
                binend: data.ranges[index + 1] ?? null // `binend` is null for the last bin
            }));
            setGraphData(transformedData);
        }
    }, [data]);

    if(!data) return (<p>No Data found for Histogram!</p>)

    return (
        <div>
            <h3>Histogram for {contaminant_type} values for {sector}</h3>
            <div className="histogram">
                <ResponsiveContainer width="100%" height={500}>
                    <BarChart
                        width={500}
                        height={500}
                        data={graphData}
                        margin={{
                            top: 20,
                            right: 100,
                            left: 100,
                            bottom: 60,
                        }}>
                        <CartesianGrid />
                        <XAxis
                            dataKey="name"
                            label={{ value: 'Bins', position: 'insideBottom', offset: -5 }}
                            tick={{ fontSize: 12 }}
                            interval={0}
                        />
                        <YAxis
                            label={{ value: 'Count of contaminant readings in Bin', angle: -90, position: 'insideLeft', dx: 0, dy: 100 }}
                        />
                        <Tooltip formatter={(value: number, name: string, props: any) => [
                            `Count: ${value}`,
                            `Range: ${props.payload.binstart.toFixed(4)} - ${props.payload.binend?.toFixed(4) || ''}`,
                        ]}
                        offset={100}
                        />
                        <Legend verticalAlign="top" height={40} />
                        <Bar type='monotone' dataKey={'count'}/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )

};
export default Histogram;
