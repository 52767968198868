// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Sectors.css */
.sectors-page {
    padding: 20px;
}

.sector-list {
    list-style-type: none;
    padding: 0;
}

.sector-list li {
    margin-bottom: 10px;
}

.sector-list a {
    text-decoration: none;
    color: #007bff;
    font-size: 1.2em;
}

.sector-list a:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sectors.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["/* src/components/Sectors.css */\n.sectors-page {\n    padding: 20px;\n}\n\n.sector-list {\n    list-style-type: none;\n    padding: 0;\n}\n\n.sector-list li {\n    margin-bottom: 10px;\n}\n\n.sector-list a {\n    text-decoration: none;\n    color: #007bff;\n    font-size: 1.2em;\n}\n\n.sector-list a:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
