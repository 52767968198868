export const sectorsList = [
    ['metal-mining-and-refining', 'Metal Mining And Refining'],
    ['inorganic-chemicals', 'Inorganic Chemicals'],
    ['petroleum-refineries', 'Petroleum Refineries'],
    ['electric-power-generation', 'Electric Power Generation'],
    ['industrial-minerals', 'Industrial Minerals'],
    ['miscellaneous-industrial', 'Miscellaneous Industrial'],
    ['pulp-and-paper', 'Pulp And Paper'],
    ['organic-chemicals-manufacturing', 'Organic Chemicals Manufacturing']
]
