export default class ContaminantsDataService {
    private readonly baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async fetchSectorData(sector: string): Promise<any> {
        try {
            const response = await fetch(`${this.baseUrl}/data/sewage/${sector}/allcontaminants`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching sector data:', error);
            throw error;
        }
    }

    public async fetchAllContaminantsData(sector: string, contaminantType: string): Promise<any> {
        try {
            const response = await fetch(`${this.baseUrl}/data/contaminants/${encodeURIComponent(sector)}/${encodeURIComponent(contaminantType)}/minmax`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching contaminants data:', error);
            throw error;
        }
    }

    public async fetchMinMaxContaminantsData(sector: string, contaminantType: string): Promise<any> {
        try {
            const response = await fetch(`${this.baseUrl}/data/contaminants/${encodeURIComponent(sector)}/${encodeURIComponent(contaminantType)}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching contaminants data:', error);
            throw error;
        }
    }

    public async fetchAllExceedancseData(): Promise<any> {
        try {
            const response = await fetch(`${this.baseUrl}/data/sewage/all`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching exceedance data:', error);
            throw new Error('Error fetching exceedance data');
        }
    }

    public async fetchExceedanceData(sector: string, contaminant: string): Promise<any> {
        try {
            const response = await fetch(`/api/data/sewage/${encodeURIComponent(sector)}/${encodeURIComponent(contaminant)}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching exceedance data:', error);
            throw new Error('Error fetching exceedance data');
        }
    }

    public async fetchSectorContaminants(sector: string): Promise<{ contaminantRouteName: string; contaminantDisplayName: string }[]> {
        try {
            const response = await fetch(`${this.baseUrl}/data/contaminants/all`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // Filter for the specific sector
            return data.filter((entry: any) => entry.sectorRouteName === sector);
        } catch (error) {
            console.error('Error fetching sector contaminants:', error);
            throw error;
        }
    };

    public async fetchHistogramData(sector: string, contaminantType: string): Promise<any> {
        try {
            const response = await fetch(`${this.baseUrl}/data/contaminants/${encodeURIComponent(sector)}/${encodeURIComponent(contaminantType)}/histogram`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching contaminants data:', error);
            throw error;
        }
    }
}
