import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ContaminantsDataService from '../services/ContaminantsDataService';
import { sectorsList } from '../assets/sectorsList';
import { contaminantsList } from '../assets/contaminantsList';
import './Sectors.css';

const exceedancesSector = 'miscellaneous-industrial';

const Sectors: React.FC = () => {
    const [contaminants, setContaminants] = useState<{ routeName: string; displayName: string }[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataService = new ContaminantsDataService('/api');
                const data = await dataService.fetchSectorContaminants('organic-chemical-manufacturing');

                // Filter out "Not Applicable" contaminants and get unique contaminants
                const uniqueContaminants: { routeName: string; displayName: string }[] = Array.from(
                    new Map(
                        data
                            .filter((item) => item.contaminantRouteName !== 'not-applicable' && item.contaminantDisplayName !== 'Not Applicable')
                            .map((item) => [
                                item.contaminantRouteName,
                                {
                                    routeName: item.contaminantRouteName,
                                    displayName: item.contaminantDisplayName,
                                },
                            ])
                    ).values()
                );

                setContaminants(uniqueContaminants);
            } catch (err) {
                setError('Failed to fetch contaminants data');
            }
        };

        fetchData();
    }, []);

    if (error) return <p>{error}</p>;

    return (
        <div className="sectors-page">
            <h2>Select a Sector</h2>
            <ul className="sector-list">
                <li>
                    <ul>
                        <details>
                            <summary>All Contaminants for Organic Chemical Manufacturing</summary>
                            {contaminants.map((contaminant) => (
                                <li key={contaminant.routeName}>
                                    <Link to={`/sector/organic-chemical-manufacturing/${contaminant.routeName}`}>
                                        {contaminant.displayName}
                                    </Link>
                                </li>
                            ))}
                        </details>
                    </ul>
                </li>
                <li>
                    <ul>
                        <details>
                            <summary>Sector Exceedances</summary>
                            {sectorsList.map((sector) => {
                                return (
                                    <li key={sector[0]}>
                                        <Link to={`/sector/contaminants/${sector[0]}`}>
                                            {sector[1]}
                                        </Link>
                                    </li>
                                );
                            })}
                        </details>
                        <details>
                            <summary>Miscellaneous Industrial - All Exceedances</summary>
                            {contaminantsList.map((contaminant) => {
                                return (
                                    <li key={contaminant[0]}>
                                        <Link to={`/sector/exceedances/${exceedancesSector}/${contaminant[0]}`}>
                                            Miscellaneous Industrial - {contaminant[1]} Exceedance
                                        </Link>
                                    </li>
                                );
                            })}
                        </details>
                    </ul>
                </li>
            </ul>
        </div>
    );
};

export default Sectors;
