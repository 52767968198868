// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
.App {
    text-align: center;
    margin-top: 20px; /* To ensure the navbar doesn't overlap with the content */
}

h1 {
    font-size: 2em;
    margin: 20px 0;
}

p {
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.2em;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,kBAAkB;IAClB,gBAAgB,EAAE,0DAA0D;AAChF;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":["/* src/App.css */\n.App {\n    text-align: center;\n    margin-top: 20px; /* To ensure the navbar doesn't overlap with the content */\n}\n\nh1 {\n    font-size: 2em;\n    margin: 20px 0;\n}\n\np {\n    max-width: 800px;\n    margin: 0 auto;\n    font-size: 1.2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
